import { version } from '$app/environment';
import { PUBLIC_SENTRY_FRONTEND_KEY } from '$env/static/public';
import * as Sentry from '@sentry/svelte';

//import crypto from 'crypto';

Sentry.init({
    dsn: PUBLIC_SENTRY_FRONTEND_KEY, 
    release: version,
    environment: import.meta.env.DEV ? "dev" : "prod",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.DEV ? 1 : 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export async function handleError({ error, event }) {
    //const errorId = crypto.randomUUID();
    // example integration with https://sentry.io/
    Sentry.captureException(error, { event });

    return {
        message: 'Whoops!',
    };
}
  

/** @type {import('@sveltejs/kit').HandleServerError} */
/*export async function handleError({ error, event }) {
    const errorId = crypto.randomUUID();
    // example integration with https://sentry.io/
    Sentry.captureException(error, { event, errorId });

    return {
        message: 'Whoops!',
        errorId
    };
}
*/
/*
import { browser } from '$app/environment'; 

const reportUrl = 'wss://wss.beeneverywhere.net:443'; // Url to the WebSocket server "wss://[host]:[port]" 

let oldLog = null;

// The reporter object encapsulating the WebSocket
const reporter = {
    socket: null,

    init: function() {
        if(browser) {
            //try {
            this.socket = new WebSocket(reportUrl);
            //} catch {
            //    console.log('failed to connect to wss');
            //}
            this.socket.addEventListener("error", (event) => {
                //console.log("WebSocket error: ", event);
                //if(oldLog) oldLog.apply(console, "WebSocket error"); 
            });
        }
    },

    event: function(eventCode, message) {
        const isReady = this.socket && this.socket.readyState === WebSocket.OPEN;
        if (isReady) { // Messages triggered before the WebSocket is ready are ignored
            //if(oldLog) oldLog.apply(console, 'error logged'); 
            this.socket.send(JSON.stringify({eventCode: eventCode, message: message}));
        } else {
            //if(oldLog) oldLog.apply(console, 'not ready to log'); 
        }
    }
};

reporter.init();


export async function handleError({ error, e }) {    
    reporter.event('JAVASCRIPT_ERROR', error + ', ' + e.message + ', ' + e.filename + ', ' + e.lineno + ':' + e.colno);
    
    let stacktrace = e.stack;
    if (!stacktrace && e.error) {
        stacktrace = e.error.stack;
    }
    if (stacktrace) {
        reporter.event('JAVASCRIPT_ERROR_STACKTRACE', stacktrace);
    }

    return {
        message: 'Whoops!',
    };
}*/

// Start reporter immediately
//if(browser) {

/*    reporter.event('connected');



// Collect unhandled JavaScript errors and send them to the server
    /*window.addEventListener('error', function(e) {
        reporter.event('JAVASCRIPT_ERROR', e.message + ', ' + e.filename + ', ' + e.lineno + ':' + e.colno);
    
        let stacktrace = e.stack;
        if (!stacktrace && e.error) {
            stacktrace = e.error.stack;
        }
        if (stacktrace) {
            reporter.event('JAVASCRIPT_ERROR_STACKTRACE', stacktrace);
        }
    });*/
/*
    (function(){
        oldLog = console.log;
        console.log = function (message) {
            // DO MESSAGE HERE.
            reporter.event('LOG', message);
            oldLog.apply(console, arguments);
        };
    })();
}
*/